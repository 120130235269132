/* NAVBAR */

* {
    box-sizing: border-box;
    background-color: black;
}

body {
    margin: 0;
    padding: 0;
}
.container {
    margin: 0;
    padding: 0;
    text-align: center;
    object-fit: cover;
}

.nav {
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 2rem;
    padding: 0 1rem;
}

.site-title {
    font-size: 2rem;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .35rem;
}

.nav li.active {
    background-color: #555;
}

.nav li.hover {
    background-color: #777;
}


/* HOME */
.main {
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
}
.text-box{
    position: fixed;
    background: black;
    height: 100%;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 180px;
    font-weight: 300;
    mix-blend-mode: multiply;
}

video {
    position: fixed;
    top: 55%;
    left: 50%;
    width: 100%;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
}

video::-webkit-media-controls {
    display: none;
  }


/* Portfolio */
.posts {
    background-color: black;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 15px;
  }
  
  .posts img {
    width: 100%;
    height: auto;
    display: block;
    transition: all 100ms ease-out;
  }
  
  .posts img:hover {
    transform: scale(1.04);
  }
/* ABOUT ME */
.pic-container img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width:  24em;
    padding: 1em;
    border-style: double;
    border-color: white;
    border-radius: 4em;
    border-width: 1em;
    
}

.pic-container h1 {
    color: white;
    text-align: center;
}

.pic-container p {
    color: white;
    text-align: center;
}

/* FOOTER */
.footer{
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0;
    padding: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
  }
.footer ul {
    padding: 1;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}
.footer a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .35rem;
}

a.social {
    margin: 0;
    transition: transform 250ms;
    display: inline-block;
  }

a.social:hover {
    transform: translateY(-2px);
  }

a.google{
    color: white;
  }
  
a.instagram {
    color: white;
  }

  